const ID_TOKEN_NAMESPACE = 'https://fantasy.freetrail.com';

export const FIELD_ALL = 'ALL';
export const FIELD_FEMALE = 'F';
export const FIELD_MALE = 'M';

export type LeaderboardVariant = 'overall' | 'pick_five' | 'pick_ten' | 'best_30';

export const FIELDS = {
  [FIELD_ALL]: 'All',
  [FIELD_FEMALE]: 'Female',
  [FIELD_MALE]: 'Male'
};

export enum EventStatus {
  Open = 'open',
  Closed = 'closed',
  ResultsPosted = 'results_posted',
  Selected = 'selected'
}

export enum EventPlatform {
  UltraSignup = 'ultrasignup',
  UTMB = 'utmb'
}

export enum EventSponsor {
  KetoneIQ = 'ketone-iq',
  HOKA = 'hoka'
}

export enum ContestType {
  PickFive = 'pick_five',
  PickTen = 'pick_ten',
  VoteFive = 'vote_five',
  VoteTen = 'vote_ten'
}

export enum EntrantType {
  Runner = 'runner_entrant',
  Performance = 'performance_entrant'
}

export function getUserName(user): string {
  return user[ID_TOKEN_NAMESPACE + '/username'] || user.name;
}

export interface EventData {
  closes_at: string;
  dates: string;
  start_date: string;
  format?: string;
  id: number;
  image_url: string;
  location: string;
  country: string;
  name: string;
  status: EventStatus;
  uuid: string;
}

interface PublicUserDetails {
  display_name: string;
  profile_image_url: string;
}

export interface LeagueUser {
  admin: boolean;
  created_at: string;
  user: PublicUserDetails;
}

export interface League {
  avatar_url: string;
  creator: PublicUserDetails;
  current_season: { id: number; events: EventData[]; year: number };
  current_user_admin: boolean;
  current_user_member: boolean;
  league_users: LeagueUser[];
  invite_code: string | undefined;
  name: string;
  uuid: string;
}

export interface LeaderboardUser {
  name: string;
  profile_image_url: string;
  total_score: number;
  female_score: number;
  male_score: number;
  events_played: number;
  points_per_event: number;
  average_rank: number;
}

interface ImageUrls {
  thumb: string;
  standard: string;
}

export interface Team {
  name: string;
  logo_urls: ImageUrls;
  profile_slug: string;
  instagram_handle: string;
  uuid: string;
  website_url: string;
}

export interface Entrant {
  id: number;
  first_name: string;
  last_name: string;
  athlete_id?: number;
  athlete?: Athlete;
  city?: string;
  state_or_country?: string;
  result_id?: number;
  result?: {
    race: {
      name: string;
    };
  };
  started_race: boolean;
  notes?: string;
  result_count?: number;
  utmb_id?: string;
  itra_id?: string;
  post_race_rank?: number;
}

export interface Athlete {
  id: number;
  uuid: string;
  first_name: string;
  last_name: string;
  profile_slug: string;
  country: string;
  profile_image_urls: ImageUrls;
  personal_url: string;
  teams: Team[];
}

export interface EventRaceResults {
  female_results: Entrant[];
  male_results: Entrant[];
}

interface Race {
  name: string;
  results_url: string;
  formatted_date: string;
}

interface Result {
  place_field: number;
  place_overall: number;
  formatted_finish_time: string;
  dnf: boolean;
  race: Race;
}

export interface HeadToHeadData {
  athlete: Athlete;
  results: Result[];
  wins: number;
  podiums: number;
  top_tens: number;
  dnfs: number;
  record: { wins: number; losses: number; ties: number };
}

export interface UserProfile {
  id: number;
  display_name: string;
  email?: string;
  email_notifications: boolean;
  strava_account: { strava_athlete_id: number };
  athlete: Athlete;
  athlete_claims: { athlete: Athlete; status: string }[];
}

export interface SplitLocation {
  id: string;
  uuid: string;
  name: string;
  distance_miles: number;
}

interface SplitCheckpoint {
  split_location_id: string;
}

export interface SplitRaceOccurrence {
  uuid: string;
  checkpoints: SplitCheckpoint[];
  year: number;
}

export interface SplitRace {
  uuid: string;
  name: string;
  occurrences: SplitRaceOccurrence[];
  locations: SplitLocation[];
}

export interface SplitRunner {
  uuid: string;
  year: number;
  field: string;
  first_name: string;
  last_name: string;
  finish_time_seconds?: number;
  finish_time_formatted: string;
  finish_place_overall?: number;
  finish_place_field?: number;
  runner_splits: any[];
}

export interface SplitsData {
  races: SplitRace[];
  runs: SplitRunner[];
}

export interface SplitsFiltersData {
  choices: {
    message: {
      tool_calls: {
        function: {
          name: string;
          arguments: string;
        };
      };
    };
  };
}
