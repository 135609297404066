import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import api from '../lib/api';
import UserContext from '../context/user-context';
import PageHeader from '../components/page-header';
import MainContent, { PaddedContent } from '../components/main-content';
import Loading from '../components/loading';
import { AthletesSubNav } from '../components/nav';
import { Team } from '../lib/model';
import { InstagramIcon } from '../components/icons';
import { LinkIcon } from '@heroicons/react/solid';
import SectionDescription from '../components/section-description';

const Teams: React.FC = () => {
  const user = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(true);
  const [teams, setTeams] = useState<Team[]>([]);

  useEffect(() => {
    const fetchTeams = async () => {
      setIsLoading(true);
      try {
        const teams = await api(user.accessToken).getTeams();
        setTeams(teams);
      } catch (error) {
        console.error('Error fetching teams:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchTeams();
  }, [user.accessToken]);

  return (
    <>
      <AthletesSubNav />
      <PageHeader title="Teams" subTitle="Select a team to view its trail running roster" />
      <MainContent>
        <PaddedContent>
          {isLoading ? (
            <Loading />
          ) : (
            <>
              <SectionDescription>
                <span className="text-gray-600">
                  Have a question or correction?{' '}
                  <a
                    className="hyperlink underline"
                    href="https://freetrail.com/contact/"
                    target="_blank"
                  >
                    Contact us
                  </a>
                  .
                </span>
              </SectionDescription>
              <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-4">
                {teams.map((team) => (
                  <Link
                    key={team.profile_slug}
                    to={`/teams/${team.profile_slug}`}
                    className="bg-purple-haze-50/0 hover:bg-purple-haze-50/10  flex items-center rounded-sm border border-gray-400 p-6 shadow-md"
                  >
                    <img
                      src={team.logo_urls.standard}
                      alt={`${team.name} logo`}
                      className="mr-4 h-14 w-14 rounded-full object-cover"
                    />
                    <div className="flex flex-col">
                      <h3 className="mb-2 text-base font-semibold text-gray-900">{team.name}</h3>
                      <div className="flex items-center space-x-2">
                        <span className="hyperlink text-sm font-medium">View Team</span>
                      </div>
                    </div>
                  </Link>
                ))}
              </div>
            </>
          )}
        </PaddedContent>
      </MainContent>
    </>
  );
};

export default Teams;
